ion-toolbar {
  --background: transparent;
}

// Set modal size for reset password
ion-modal {
  &.app-fullscreen {
    --background: var(--ion-color-light);

    align-items: flex-end;

    &.md {
      --height: 96%;

      .modal-wrapper {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }

    &.green {
      --background: var(--ion-color-success);

      ion-content,
      ion-toolbar {
        --background: var(--ion-color-success);
      }

      ion-header {
        background-color: var(--ion-color-success);
      }
    }

    ion-toolbar {
      --padding-top: 0;

      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  &.greenness-modal,
  &.greenness-notification-modal {
    --height: 90%;
    --border-radius: var(--radius-4);

    ion-header {
      background-color: var(--color-core-neutral-0);
    }

    &::part(backdrop) {
      background-color: var(--color-core-neutral-10);
      opacity: 0.5;
    }

    &::part(content) {
      position: absolute;
      bottom: 0;
    }

    ion-content {
      &::part(background) {
        --background: var(--color-core-neutral-0);
      }
    }
  }

  &.greenness-notification-modal {
    --height: auto;
  }
}

ion-header {
  background-color: var(--ion-color-light);

  h1 {
    font-family: var(--font-secondary);
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }
}

ion-content {
  &.ion-content__subpage {
    --color: var(--ion-color-primary);

    h1 {
      font-family: var(--font-secondary);
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      margin: 0;
    }

    h2 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      font-weight: bold;
      margin: 0 0 4px;
    }
  }

  p.with-tiny-subtitle {
    span {
      display: block;
      font-size: 12px;
      margin-top: -4px;
    }
  }
}

.ion-alert--custom {
  --background: var(--ion-color-white);

  &.ios {
    --ion-color-primary: rgb(56, 128, 255);
  }
}

// Fix color of custom ion icons
ion-icon {
  stroke: var(--ion-color-base);
}

// Set custom styles for main tabs
ion-tab-bar {
  --background: var(--ion-color-white);

  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);

  &.ios {
    height: 56px;
  }

  &.md {
    ion-icon {
      font-size: 26px;
    }
  }
}

ion-button {
  --border-radius: 0;
  --padding-start: 40px;
  --padding-end: 40px;
}

ion-tab-button {
  --color: var(--ion-color-tertiary);
  --color-selected: var(--ion-color-primary);
  --ripple-color: var(--ion-color-danger);
}

ion-back-button {
  &.ios {
    &.back-button-has-icon-only {
      padding: 2px 5px;
    }
  }
}

ion-toast {
  color: var(--ion-color-primary-contrast);

  &:focus {
    outline: none;
  }

  &::part(message) {
    color: var(--ion-color-primary-contrast);
  }

  &::part(button) {
    width: auto;
    height: auto;
    font-size: 14px;
    padding: 14px 0 0;
  }

  &::part(container) {
    align-items: flex-start;
  }
}

ion-picker {
  touch-action: none;
}

ion-row {
  margin: 8px;
}

ion-input {
  &.input-label-placement-stacked {
    &.sc-ion-input-md-h {
      min-height: 68px;
    }
    .label-text {
      text-transform: uppercase;
      color: inherit;
    }
  }
}

.sc-ion-input-md-h {
  --highlight-color: var(--ion-color-primary);
  --highlight-color-valid: var(--ion-color-primary);
  --highlight-color-invalid: var(--ion-color-danger);
}

ion-item {
  &.item-input,
  &.item-select {
    --background-focused: transparent;
    --background: transparent;
    --padding-start: 0;
    --highlight-color-valid: var(--ion-color-primary);
    --highlight-color-invalid: var(--ion-color-primary);
    --border-color: var(--ion-color-primary);
    --border-width: 0 0 2px 0;
    --inner-padding-end: 0;

    ion-label {
      &.label-stacked {
        text-transform: uppercase;
      }
    }

    &.ion-touched {
      --highlight-color-invalid: var(--ion-color-danger);
    }

    &.ion-touched.ion-invalid {
      --border-color: var(--ion-color-danger);
    }

    &.item-interactive-disabled {
      --border-color: rgba(0, 0, 0, 0.3);
    }

    ion-icon[name='eye'],
    ion-icon[name='eye-off'] {
      transform: translate(-2px, 14px);
      font-size: 20px;
    }
  }
}

.ion-input__errors {
  text-align: left;
  font-size: 13px;
  height: 16px;
  padding-top: 3px;

  span {
    color: var(--ion-color-danger);
    line-height: 1;
  }
}

.account-phone-group {
  position: relative;
  margin-top: 40px;
  &.only-ch {
    .iti__selected-flag {
      pointer-events: none;
    }
    .iti__arrow {
      border: none;
    }
  }

  .iti__flag-container {
    &:after {
      position: absolute;
      top: 2px;
      right: 0;
      content: '';
      width: 10px;
      height: 100%;
      background: #f8f6f1;
      z-index: 1;
    }
  }

  .iti--separate-dial-code {
    .iti__selected-flag {
      background-color: transparent;
      &:after {
        position: absolute;
        top: 2px;
        right: 0;
        content: '';
        width: 10px;
        height: 100%;
        background: #f8f6f1;
        z-index: 1;
      }
    }
    .iti__arrow {
      position: relative;
      z-index: 2;
    }
  }
  .iti {
    .country-dropdown {
      min-width: 250px;
    }
    &.iti--allow-dropdown {
      display: block;
    }
  }
  > label {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    text-align: left;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid black;
    color: black;
    display: block;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 2;
    padding: 0;
    outline: none;
    width: 100%;
  }
  &.invalid {
    > label {
      color: var(--ion-color-danger);
    }
    input {
      border-bottom-color: var(--ion-color-danger);
    }
  }
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-text-color-step-800, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #0054e9);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
